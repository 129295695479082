<template>
    <div>
      <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
      <div v-else>
        <div class="man-title">
          机器人管理
          <el-button @click="add" class="btn" type="primary" size="mini" :disabled="roleId == 4">新增机器人</el-button>
        </div>
        <div class="list-box">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="name" label="机器人名称"></el-table-column>
            <el-table-column prop="webHook" label="机器人地址"></el-table-column>
            <el-table-column prop="remarks" label="备注"></el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-dropdown placement="bottom-start" trigger="click">
                  <span @click.stop="">
                    <span class="el-dropdown-link">
                      操作<i class="el-icon-arrow-down"></i>
                    </span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :disabled="roleId == 4"><div @click="detail(scope.row)">详情</div></el-dropdown-item>
                    <el-dropdown-item :disabled="roleId == 4"><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                    <el-dropdown-item :disabled="roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import dayjs from 'dayjs';

  export default {
    data() {
      return {
        loading: false,
        tableData: [],
        roleId: 0
      };
    },
    methods: {
      dayjs,
      detail(row) {
        this.$router.push({ name: 'robotDetail', params: row })
      },
      edit(row) {
        this.$router.push({ name: 'robotEdit', params: row })
      },
      dlt(row) {
        this.$confirm("确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$ajax.post("entWechatRobotDelete", {
            robotId: row.robotId,
          }).then((res) => {
            this.loadList();
          });
        });
      },
      add() {
        this.$router.push({ name: 'robotAdd' })
      },
      loadList() {
        this.loading = true
        this.$ajax.post("entWechatRobotQuery").then((res) => {
          this.tableData = res.data;
          this.loading = false
        }).catch(err=>{
          this.loading = false
        })
      },
    },
    mounted() {
      this.loadList();
      this.roleId = sessionStorage.getItem('r')
    },
  };
  </script>
  
  <style lang="less" scoped>
  .mains {
    padding: 400px 0;
    height: 100%;
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 50px;
  }
  .btn {
    float: right;
  }
  </style>